import {useRef} from 'react';
import cn from 'classnames';

import {twMerge} from '@/stylesheets/twMerge';
import {BLOG_PAGE_TYPE, type BlogCardProps} from '@/components/blog/types';
import {useTranslations} from '@/hooks/useTranslations';
import {topicEnPath, filterArticleTag} from '@/components/pages/blog/utils';
import Typography from '@/components/base/elements/Typography/Typography';
import {BlogAuthorName} from '@/components/pages/blog/BlogAuthor/BlogAuthorName';
import {getMetafieldAuthors} from '@/pages/shopify.com/($locale)/enterprise/blog/utils/utils';

import ImageWrapper from './ImageWrapper';
import {
  blogCardHorizontalTitleStyles,
  eyebrowStyles,
  articleAuthorStyles,
  blogCardTagStyles,
  blogCardDateStyles,
  blogCardHorizontalTextStyles,
} from './style';

export default function BlogCardHorizontal({
  article,
  enBlogPath,
  type,
  preferredTag,
  blogType,
  translationNamespace,
  mode,
}: BlogCardProps) {
  const linkRef = useRef<HTMLAnchorElement | null>(null);
  const {localizePath} = useTranslations();
  const authorName = article.authorV2?.name;
  const isAuthorShown = type === 'hero';
  const articleTag = filterArticleTag(article.tags);

  const isEnterpriseBlog = blogType === BLOG_PAGE_TYPE.ENTERPRISE;

  const enterpriseAuthors = isEnterpriseBlog
    ? getMetafieldAuthors(article?.metafields, article.authorV2?.name)
    : [];

  const getTagLink = () => {
    if (preferredTag?.startsWith('/')) {
      return preferredTag;
    }

    return localizePath(
      topicEnPath(
        enBlogPath,
        isEnterpriseBlog && preferredTag ? preferredTag : articleTag,
      ),
    );
  };

  const isEnterpriseAuthors = isEnterpriseBlog && enterpriseAuthors;

  const authorData = isEnterpriseAuthors
    ? enterpriseAuthors
    : [{name: authorName}];

  return (
    // NOTE: SEO uses "article--index" class for analysis
    <article className="article--index flex items-stretch justify-between space-x-4 min-w-full">
      <div className="blogPost">
        {articleTag && (
          <div className={eyebrowStyles}>
            <a
              href={getTagLink()}
              className={twMerge(
                cn(blogCardTagStyles({blogType, mode}), 'no-underline'),
              )}
            >
              {articleTag}
            </a>
          </div>
        )}
        <div className={blogCardHorizontalTitleStyles}>
          <a
            className={blogCardHorizontalTextStyles({blogType, mode})}
            ref={linkRef}
            href={article.path ?? localizePath(enBlogPath + article.handle)}
          >
            {article.title}
          </a>
        </div>
        <div>
          <BlogAuthorName
            blogPageType={blogType}
            ns={translationNamespace || `pages${enBlogPath}content`}
            enBlogPath={enBlogPath}
            authorData={isAuthorShown ? authorData : []}
            className={articleAuthorStyles({isMobileHidden: true, blogType})}
          />
          <Typography
            as="span"
            className={twMerge(
              'text-sm font-shopifysans font-normal text-shade-50',
              blogCardDateStyles({blogType, mode}),
            )}
          >
            {article.modifiedAt}
          </Typography>
        </div>
      </div>

      {article.imageUrl && (
        <ImageWrapper
          href={linkRef.current?.href}
          className="flex-none bg-[red] w-24 h-24 tablet:w-40 tablet:h-40 overflow-hidden"
        >
          <img
            className="min-w-full min-h-full object-cover hover:scale-105 transition-transform duration-300 cursor-pointer"
            src={article.imageUrl}
            alt={article.imageAltText || article.title}
          />
        </ImageWrapper>
      )}
    </article>
  );
}
