import type {ReactNode} from 'react';
import cn from 'classnames';

import type {Article} from '@/components/blog/types';
import {BLOG_PAGE_TYPE} from '@/components/blog/types';
import BlogCard from '@/components/pages/blog/BlogCard/BlogCard';
import BlogCardHorizontal from '@/components/pages/blog/BlogCard/BlogCardHorizontal';
import {twMerge} from '@/stylesheets/twMerge';
import {blogCardBorderStyles} from '@/components/pages/blog/BlogCard/style';
import type {Mode} from '@/components/base/types';

import {containerStyles} from './styles';

interface HeroProps {
  featuredArticle?: Article;
  secondaryArticles: Article[];
  enBlogPath: string;
  conversionCta?: ReactNode;
  blogType?: BLOG_PAGE_TYPE;
  mode?: Mode;
}

export default function Hero({
  featuredArticle,
  secondaryArticles,
  enBlogPath,
  conversionCta,
  blogType,
  mode,
}: HeroProps) {
  const isEnterpriseBlog = blogType === BLOG_PAGE_TYPE.ENTERPRISE;
  const isEngineeringBlog = blogType === BLOG_PAGE_TYPE.ENGINEERING;

  return (
    <div className="container">
      <div className={containerStyles({isEnterpriseBlog})}>
        {featuredArticle && (
          <div className="basis-1/2 mb-6">
            <BlogCard
              blogType={blogType}
              article={featuredArticle}
              enBlogPath={enBlogPath}
              translationNamespace={isEngineeringBlog ? 'pages/content' : ''}
              type="hero"
              preferredTag={
                isEnterpriseBlog ? featuredArticle.topicHandle : undefined
              }
              mode={mode}
            />
          </div>
        )}
        <div className="basis-1/2">
          {conversionCta}
          {secondaryArticles.length > 0 && (
            <div className="flex justify-between flex-col space-y-9 mt-9">
              {secondaryArticles.map((article: Article, index: number) => {
                return (
                  <div
                    className={twMerge(
                      cn(
                        {'border-opacity-10': isEnterpriseBlog},
                        'pt-6 border-t flex justify-between space-y-9',
                        blogCardBorderStyles({blogType}),
                      ),
                    )}
                    key={'heroSecondaryArticle' + index}
                  >
                    <BlogCardHorizontal
                      blogType={blogType}
                      article={article}
                      translationNamespace={
                        isEngineeringBlog ? 'pages/content' : ''
                      }
                      enBlogPath={enBlogPath}
                      type="hero"
                      preferredTag={
                        isEnterpriseBlog ? article.topicHandle : undefined
                      }
                      mode={mode}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
